<template>
  <div class="px-8 py-12">
    <card :title="$t('integrators.title')">
      <div class="mb-6 -mt-5">
        <l-input
          v-model="query"
          class="max-w-lg"
          icon="search"
          icon-class="text-blue"
        />
      </div>
      <div
        class="flex flex-row border-b border-gray-200 text-gray-500 text-sm pb-2"
      >
        <div class="w-3/12">{{ $t('integrators.name') }}</div>
        <div class="w-4/12">{{ $t('integrators.token') }}</div>
        <div class="w-2/12">{{ $t('integrators.creationTime') }}</div>
        <div class="w-2/12">{{ $t('integrators.status') }}</div>
        <div class="w-1/12">{{ $t('integrators.devPortal') }}</div>
      </div>
      <div
        v-for="(integrator, index) of filteredIntegrators"
        :key="index"
        class="my-4 flex items-center text-blue-600 text-sm"
      >
        <div class="flex items-center w-3/12">
          <div>
            <l-image-upload
              v-model="integrator.logo"
              class="h-12 w-12"
              :class="{ invisible: !integrator.logo }"
              drag-drop
              disabled
            />
          </div>
          <div class="pl-4">
            {{ integrator.name }}
          </div>
        </div>
        <div class="w-4/12">{{ integrator.token }}</div>
        <div class="w-2/12">
          {{ formattedDate(integrator.creationTime) }}
        </div>
        <div class="w-2/12">
          <div
            class="flex max-w-min p-2 rounded-lg"
            :class="getClass(integrator.status)"
          >
            <icon class="mr-1" :name="getIcon(integrator.status)" />
            <div>
              {{ getStatus(integrator.status) }}
            </div>
          </div>
        </div>
        <div class="w-1/12">
          <icon
            name="external"
            class="w-6 h-6 text-violet cursor-pointer"
            @click="goToDevPortal(integrator.userId)"
          />
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '@last/core-ui/components/LCard.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LImageUpload from '@last/core-ui/components/LImageUpload.vue'
import lastUtils from '@last/core/src/lastUtils'
import { mapState } from 'vuex'
import api from '@/api'
import moment from 'moment'

const CLASS = {
  prod: 'prod',
  test: 'test',
  pending: 'pending'
}

const ICON = {
  prod: 'check-circle',
  test: 'prohibit',
  pending: 'alert'
}

export default {
  name: 'Integrators',
  components: {
    Card,
    Icon,
    LInput,
    LImageUpload
  },
  props: {},
  data() {
    return {
      query: '',
      integrators: []
    }
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    filteredIntegrators() {
      if (!this.query) return this.integrators
      return this.integrators.filter(integrator => {
        return lastUtils.fuzzy(integrator.name, this.query)
      })
    }
  },
  async mounted() {
    const response = await api.get('/support/integrators')
    this.integrators = response.data
  },
  methods: {
    goToDevPortal(userId) {
      window.open(
        `${process.env.VUE_APP_LAST_DEVELOPER_URL}/support/login?supportToken=${this.accessToken}&userId=${userId}&tester=true`
      )
    },
    formattedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getClass(status) {
      return CLASS[status]
    },
    getStatus(status) {
      if (!status) return ''
      return this.$t(`integrators.${status}`)
    },
    getIcon(status) {
      return ICON[status]
    }
  }
}
</script>

<style scoped>
.prod {
  color: rgb(33, 190, 203);
  background-color: rgb(194, 241, 245);
}
.test {
  color: rgb(168, 168, 200);
  background-color: rgb(243, 243, 247);
}
.pending {
  color: rgb(229, 174, 0);
  background-color: rgb(255, 249, 229);
}
</style>
